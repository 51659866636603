import React from "react"
import Footer from "@/components/footer"
import Layout from "@/components/layout"
import ContentPage from "@/components/Finalistas2021"
import SearchContextProvider from "@/context/search-context"
import MenuContextProvider from "@/context/menu-context"
import Header from "@/components/header-bar"

const Finalistas = () => {
  return (
    <MenuContextProvider>
      <SearchContextProvider>
        <Layout PageTitle="Projetos Finalistas 2021">
          <Header />
          <ContentPage title="Projetos Finalistas 2021" />
          <Footer />
        </Layout>
      </SearchContextProvider>
    </MenuContextProvider>
  );
};

export default Finalistas;